import React from 'react'
import styles from './MyButton.module.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

function MyButton({ className, children, type, size, url, isBlank }) {
  return (
    <div className={classNames(styles.myButton, className)}>
      {!url ? (
        <button
          className={`button-content button-content--${type} button-content--${size}`}
        >
          {children}
        </button>
      ) : (
        <Link
          className={`button-content button-content--${type} button-content--${size}`}
          to={url}
          rel={isBlank && 'nofollow'}
          // target={isBlank && '_blank'}
        >
          {children}
        </Link>
      )}
    </div>
  )
}

export default MyButton
