import React, { useEffect, useState } from 'react'
import styles from './Header.module.scss'
import images from 'assets/images'
import MyButton from 'components/MyButton'
import useResizeWidth from 'controller/hooks/useResizeWidth'
import { Link } from 'react-router-dom'

const arrMenu = [
  {
    title: 'Blog',
    link: 'https://blog.finwallet.com/',
  },
  {
    title: 'Docs',
    link: 'http://docs.finwallet.app/',
  },
  {
    title: 'Support',
    link: 'https://finwallet.link/discord',
  },
]

function Header() {
  const { isMobile } = useResizeWidth()
  const [isOpen, setIsOpen] = useState(false)

  const handleMenu = () => {
    setIsOpen(!isOpen)
  }

  const renderMenu = () => {
    return arrMenu.map((item, i) => {
      return (
        <div>
          <a href={item.link} key={i} target='_blank' rel='nofollow'>
            {item.title}
          </a>
        </div>
      )
    })
  }

  useEffect(() => {
    !isMobile && setIsOpen(false)
  }, [isMobile])

  return (
    <div className={styles.header}>
      {isMobile && (
        <div className='download-app'>
          <div className='download-app--left'>
            <img src={images.logoApp} alt='' className='img-logo-app' />

            <div className='text-title'>
              <h6 className='text-name'>Fin Wallet</h6>

              <div className='text-description'>The fastest Sei wallet</div>
            </div>
          </div>

          <MyButton className='button-custom' size='small' url='/download'>
            Download
          </MyButton>
        </div>
      )}

      <div className='header-content'>
        <div className='header-content--left'>
          <Link to='/'>
            <img
              src={images[!isMobile ? 'logoFull' : 'logo']}
              alt=''
              className={`img-logo ${isMobile && 'img-logo--mobile'}`}
            />
          </Link>
        </div>

        <div className='header-content--center'>{renderMenu()}</div>

        <div className='header-content--right'>
          {!isMobile ? (
            <MyButton size='small' url='/download'>
              Download
            </MyButton>
          ) : (
            <img
              src={images[!isOpen ? 'menu' : 'close']}
              alt=''
              className='icon-menu'
              onClick={handleMenu}
            />
          )}
        </div>
      </div>

      <div className={`header-mobile ${isOpen && 'header-mobile--open'}`}>
        <div className='content-menu-mobile'>{renderMenu()}</div>
      </div>
    </div>
  )
}

export default Header
