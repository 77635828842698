const images = {
  logoFull: require('./logos/logoFull.png'),
  logoApp: require('./logos/logoApp.png'),
  logo: require('./logos/logo.png'),

  availableOn: require('./banner/availableOn.png'),
  waveImg: require('./banner/waveImg.png'),

  walletActions: require('./sections/walletActions.png'),
  tokenSei1: require('./sections/tokenSei1.png'),
  tokenSei2: require('./sections/tokenSei2.png'),

  imgNFT: require('./sections/imgNFT.png'),
  privacyAndSecurity: require('./sections/privacyAndSecurity.png'),

  imgSendAction: require('./sections/imgSendAction.png'),
  imgSendAction1: require('./sections/imgSendAction1.png'),
  imgSendAction2: require('./sections/imgSendAction2.png'),

  imgSecurity: require('./sections/imgSecurity.png'),

  dagora: require('./sections/icons/dagoraIcon.png'),
  eclipsePad: require('./sections/icons/eclipsePad.png'),
  kyptonite: require('./sections/icons/kyptonite.png'),
  levanaProtocol: require('./sections/icons/levanaProtocol.png'),
  simba: require('./sections/icons/simba.png'),
  sparrowSwap: require('./sections/icons/sparrowSwap.png'),
  seaSwap: require('./sections/icons/seaSwap.png'),

  chrome: require('./sections/icons/chrome.png'),
  firefox: require('./sections/icons/firefox.png'),
  edge: require('./sections/icons/edge.png'),
  ios: require('./sections/icons/ios.png'),
  android: require('./sections/icons/android.png'),

  nft1: require('./sections/nft1.png'),
  nft2: require('./sections/nft2.png'),

  twitter: require('./sections/icons/twitter.svg').default,
  discord: require('./sections/icons/discord.svg').default,
  telegram: require('./sections/icons/telegram.svg').default,
  mail: require('./sections/icons/mail.svg').default,

  menu: require('./sections/icons/menu.png'),
  close: require('./sections/icons/close.svg').default,
};

export default images;
