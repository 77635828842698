import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

const SEO = ({ title, description }) => {
  console.log({ title, description })
  useEffect(() => {
    // Use Helmet here if you need to dynamically update meta tags after the initial load
  }, [title, description])

  return (
    <Helmet>
      <title>{title}</title>
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
    </Helmet>
  )
}

export default SEO
