import React from 'react'
import images from 'assets/images'
import Footer from 'components/Footer'
import classNames from 'classnames'

import styles from './DownloadScreen.module.scss'
import useResizeWidth from 'controller/hooks/useResizeWidth'

const arrVersion = [
  {
    title: 'Browser Extension',
    app: [
      {
        isActive: true,
        name: 'Chrome',
        img: images.chrome,
        link:
          'https://chrome.google.com/webstore/detail/fin-wallet-for-sei/dbgnhckhnppddckangcjbkjnlddbjkna',
      },
      {
        isActive: true,
        name: 'Firefox',
        img: images.firefox,
        link: 'https://addons.mozilla.org/en-US/firefox/addon/fin-wallet/',
      },
      {
        isActive: true,
        name: 'Edge',
        img: images.edge,
        link:
          'https://microsoftedge.microsoft.com/addons/detail/fin-wallet-for-sei/dicbjakpncejbglbiapjhgdfhoomeomh',
      },
    ],
  },

  {
    title: 'Mobile App',
    isComing: true,
    app: [
      {
        isActive: false,
        name: 'iOS',
        img: images.ios,
        link: '#',
      },
      {
        isActive: false,
        name: 'Android',
        img: images.android,
        link: '#',
      },
    ],
  },
]

function DownloadScreen({ className }) {
  const { isMobile } = useResizeWidth()

  return (
    <div className={classNames(styles.downloadScreen, className)}>
      <div className='section-body section-7'>
        <div className='content-body'>
          <h1>
            Let Fin Wallet Guide Your {!isMobile && <br />}
            Oceanic Exploration
          </h1>

          <div className='list-version'>
            {arrVersion.map((item, i) => {
              return (
                <div className='list-version--browser' key={i}>
                  <h3>
                    {item.title}
                    {item.isComing && (
                      <div className='badge-coming'>Coming soon</div>
                    )}
                  </h3>

                  <div className='list-item'>
                    {item.app.map((itemChild, i) => {
                      if (itemChild.isActive) {
                        return (
                          <a
                            href={itemChild.link}
                            target='_blank'
                            rel='nofollow'
                            className={`item-version ${
                              !itemChild.isActive && 'item-version--disable'
                            }`}
                          >
                            <img
                              src={itemChild.img}
                              alt=''
                              className='item-icon'
                            />

                            <div className='text-download'>
                              {itemChild.name}
                            </div>
                          </a>
                        )
                      } else {
                        return (
                          <div
                            className={`item-version ${
                              !itemChild.isActive && 'item-version--disable'
                            }`}
                          >
                            <img
                              src={itemChild.img}
                              alt=''
                              className='item-icon'
                            />

                            <div className='text-download'>
                              {itemChild.name}
                            </div>
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <Footer />
      </div>
    </div>
  )
}

export default DownloadScreen
