import { useEffect, useState } from 'react';

function useResizeWidth() {
  const isWindow = typeof window !== 'undefined';

  const [widthResize, setWidthResize] = useState(window.innerWidth);
  const [heightResize, setHeightResize] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const isMobile = widthResize < 768;

  const onResizeScreen = () => {
    setWidthResize(window.innerWidth);
    setHeightResize(window.innerHeight);
  };

  const onScrollPosition = () => {
    setPositionY(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('resize', onResizeScreen);
    window.addEventListener('scroll', onScrollPosition);

    return () => {
      window.removeEventListener('scroll', onScrollPosition);
      window.removeEventListener('resize', onResizeScreen);
    };
  }, []);

  useEffect(() => {
    isWindow && setWidthResize(window.innerWidth);
    isWindow && setHeightResize(window.innerHeight);
  }, [isWindow]);

  return { widthResize, positionY, heightResize, isMobile };
}

export default useResizeWidth;
