import React from 'react'
import styles from './LandingBody.module.scss'
import Banner from './Banner'
import images from 'assets/images'
import MyButton from 'components/MyButton'
import useResizeWidth from 'controller/hooks/useResizeWidth'
import DownloadScreen from 'components/DonwloadScreen'

const arrEcosystem = [
  {
    name: 'Dagora',
    img: images.dagora,
  },
  {
    name: 'Eclipse Fi',
    img: images.eclipsePad,
  },
  {
    name: 'Kryptonite',
    img: images.kyptonite,
  },
  {
    name: 'Sea Swap',
    img: images.seaSwap,
  },
  {
    name: 'Levana Protocol',
    img: images.levanaProtocol,
  },
  {
    name: 'Simba',
    img: images.simba,
  },
  {
    name: 'SparrowSwap',
    img: images.sparrowSwap,
  },
]

function LandingBody() {
  const { isMobile } = useResizeWidth()

  return (
    <div className={styles.landingBody}>
      <div className='body-content'>
        <Banner />

        <h2 className='title-text'>
          One wallet. {!isMobile ? <br /> : null} Full access to Sei Ecosystem
        </h2>

        <div className='section-body section-2 height-body'>
          <div className='content-body'>
            <img src={images.walletActions} alt='' className='wallet-action' />

            <h3>
              <span>Non-custodial storage</span> {isMobile && <br />} of all
              crypto assets
            </h3>
          </div>
        </div>

        <div className='section-body section-3 height-body'>
          <div className='bg-blur-2'></div>
          <div className='content-body'>
            <img
              src={images.tokenSei1}
              alt=''
              className='token-sei token-sei--token-1'
            />

            <img
              src={images.tokenSei2}
              alt=''
              className='token-sei token-sei--token-2'
            />

            {/* <img src={images.imgNFT} alt='' className='bg-nft' /> */}

            <div className='img-content'>
              <img
                src={images.privacyAndSecurity}
                alt=''
                className='bg-content'
              />
            </div>

            <h3>
              <span>Mint and collect {isMobile && <br />} NFTs</span> in hand
            </h3>
          </div>
        </div>

        <div className='section-body section-4 height-body'>
          <div className='content-body'>
            <img src={images.nft1} alt='' className='img-nft img-nft--nft-1' />
            <img src={images.nft2} alt='' className='img-nft img-nft--nft-2' />

            <img src={images.imgSendAction} alt='' className='img-send' />

            <h3>
              Send and receive tokens with <span>low fees</span>
            </h3>
          </div>
        </div>

        <div className='section-body section-5 height-body'>
          <div className='content-body'>
            <img
              src={images.imgSendAction1}
              alt=''
              className='img-send-action img-send-action--send-1'
            />
            <img
              src={images.imgSendAction2}
              alt=''
              className='img-send-action img-send-action--send-2'
            />

            <img src={images.imgSecurity} alt='' className='img-security' />

            <h3>
              Personal data {isMobile && <br />} <span>privacy & security</span>
            </h3>
          </div>
        </div>

        <div className='section-body section-6 height-body'>
          <div className='content-body'>
            <h2>Discover the entire Sei Ecosystem</h2>

            <div className='list-ecosystem'>
              {arrEcosystem.map((item, i) => {
                return (
                  <div className='item-eco' key={i}>
                    <img src={item.img} alt='' className='img-icon' />

                    <div className='item-eco--text'>{item.name}</div>
                  </div>
                )
              })}
            </div>

            <div className='action-btn'>
              <MyButton url='https://www.sei.io/ecosystem' isBlank={true}>
                View All Sei Ecosystem
              </MyButton>
            </div>
          </div>
        </div>

        <DownloadScreen />
      </div>
    </div>
  )
}

export default LandingBody
