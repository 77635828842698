import Header from 'components/Header'
import SEO from 'components/SEO'
import LandingBody from 'pages'
import Download from 'pages/Download'
import { Route, Routes, useLocation } from 'react-router-dom'

function App() {
  const { pathname } = useLocation()
  const isDownloadPage = pathname === '/download'

  const canonical =
    pathname !== '/download'
      ? 'https://finwallet.com/'
      : 'https://finwallet.com/download'

  const titleMeta = isDownloadPage
    ? 'Download | Fin Wallet For Sei'
    : 'Fin Wallet For Sei'
  const descriptionMeta = isDownloadPage
    ? 'Install Fin - the fastest and easiest-to-use wallet to experience the Sei ecosystem without any hassle'
    : 'The fastest and easiest-to-use Sei wallet. Tailored for Sei users, Fin Wallet simplifies the exploration and contribution of users to Sei projects.'

  return (
    <div className='App'>
      <SEO title={titleMeta} description={descriptionMeta} />
      <link rel='canonical' href={canonical} />
      <Header />

      <Routes>
        <Route element={<LandingBody />} path='/' />
        <Route element={<Download />} path='/download' />
      </Routes>
    </div>
  )
}

export default App
