import React from 'react';
import styles from './Banner.module.scss';
import MyButton from 'components/MyButton';
import images from 'assets/images';
import useResizeWidth from 'controller/hooks/useResizeWidth';

function Banner() {
  const { isMobile } = useResizeWidth();

  return (
    <div className={styles.banner}>
      <div className='content'>
        <h1>
          The Fastest {isMobile && <br />} <span>Sei Wallet</span>
        </h1>

        <div className='text-description'>
          Fin Wallet is the first native crypto wallet for Sei Network. It is
          the fastest and easiest way to {isMobile && <br />} access the Sei
          ecosystem.
        </div>

        <MyButton url='/download'>Download Now</MyButton>

        <img src={images.availableOn} alt='' className='img-available' />
      </div>

      <div className='bg-wave'>
        <img src={images.waveImg} alt='' className='img-wave' />
      </div>
    </div>
  );
}

export default Banner;
