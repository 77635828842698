import React from 'react'
import styles from './Download.module.scss'
import DownloadScreen from 'components/DonwloadScreen'
import images from 'assets/images'

function Download() {
  return (
    <div className={styles.download}>
      <DownloadScreen className='download-screen' />

      <img src={images.waveImg} alt='' className='img-wave' />
    </div>
  )
}

export default Download
