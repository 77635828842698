import React from 'react'
import styles from './Footer.module.scss'
import images from 'assets/images'

const arrMenu = [
  {
    title: 'Blog',
    link: 'https://blog.finwallet.com/',
  },
  {
    title: 'Docs',
    link: 'http://docs.finwallet.app/',
  },
  {
    title: 'Support',
    link: 'https://discord.com/invite/3nJknTGkXn',
  },
  {
    title: 'Terms',
    link: 'https://docs.finwallet.com/legal/terms-of-service',
  },
  {
    title: 'Privacy',
    link: 'https://docs.finwallet.com/legal/privacy-policy',
  },
]

const arrSocial = [
  {
    icon: images.twitter,
    link: 'https://twitter.com/fin_wallet',
  },
  {
    icon: images.discord,
    link: 'https://finwallet.link/discord',
  },
  {
    icon: images.telegram,
    link: 'https://t.me/fin_wallet',
  },
  {
    icon: images.mail,
    link: 'mailto:hi@finwallet.com',
    isEmail: true,
  },
]

function Footer() {
  return (
    <div className={styles.footer}>
      <div className='content-footer'>
        <img src={images.logo} alt='' className='logo' />

        <div className='text-footer'>© 2023 FinWhale Labs, LLC</div>

        <div className='menu-ft'>
          {arrMenu.map((item, i) => {
            return (
              <div className='item-menu' key={i}>
                <a href={item.link} target='_blank' rel='nofollow'>
                  {item.title}
                </a>
              </div>
            )
          })}
        </div>

        <div className='social-icon'>
          {arrSocial.map((item, i) => {
            return (
              <a
                href={!item.isEmail ? item.link : `mailto: ${item.link}`}
                target='_blank'
                rel='nofollow'
              >
                <div className='item-social' key={i}>
                  <img src={item.icon} alt='' className='icon-img' />
                </div>
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Footer
